
































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Widget from "@/components/common/Widget.vue";
import DataTable from "@/components/table/DataTable.vue";
// import ClientDropDown from '@/components/common/TopDropDown.vue';
import store from "../../store";
import api from "../../api";
import { Watch } from "vue-property-decorator";
import { isEmailValid } from "@/helper/utils";
import lodash from "lodash";
import SelectFilter from "@/components/common/SelectFilter.vue";
import {
  setAgencyStorage,
  getAgencyStorage,
  getClientStorage,
} from "@/helper/utils";
import { getAuthTypeText } from "@/auth";

declare const $: any;

@Component({ components: { Breadcrumb, Widget, DataTable, SelectFilter } })
export default class AgencyEmployees extends Vue {
  tableColums = [
    {
      label: "ID",
      prop: "id",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "E-Mail Adresse",
      prop: "username",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Vorname",
      prop: "firstname",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Nachname",
      prop: "lastname",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Level",
      prop: "level",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Auth verfahren",
      prop: "authTypeText",
      bodyCellClass: "text-left",
      sortable: true,
    },
  ];
  perPage = 25;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows: any[] = [];
  filteredRows: any[] = [];
  perPageList = [5, 25, 50, 100, 250];
  user = {
    firstname: "",
    lastname: "",
    username: "",
    level: 1,
    password: "",
    passwordGenerated: true,
    userflags: 0,
  };
  userId = "";
  userToDelete = "";
  mandators: any[] = [];
  empMandators: any[] = [];
  agencies: any[] = [];
  dropdownSearch: string = "";
  timer: any = null;
  cachedAgencies: any[] = [];
  isLoaded: boolean = false;
  isFormValidate: any = "";
  isErrorUsername: boolean = false;
  isErrorEmail: boolean = false;

  constructor() {
    super();
    this.getAgencies();
  }

  @Watch("user", { immediate: true, deep: true })
  onUserChange() {
    if (
      this.user.firstname == "" ||
      this.user.lastname == "" ||
      this.user.username == "" ||
      (this.user.password == "" && !this.user.passwordGenerated)
    ) {
      this.isFormValidate = true;
    } else {
      this.isFormValidate = false;
    }
  }

  mounted() {
    // this.getMandators()
    //Support Bootstrap inside vuejs
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.clearOnEsc();
      }
    });
    if (getClientStorage()) {
      store.dispatch("updateSelectedClient", getClientStorage()[0]);
      setAgencyStorage(getClientStorage()[0]);
      this.getEmployees();
    } else if (getAgencyStorage()) {
      store.dispatch("updateSelectedClient", getAgencyStorage());
      this.getEmployees();
    } else {
      this.getEmployees();
    }
  }

  async getEmployees() {
    if (!this.agency || this.isLoaded) {
      return;
    }
    this.pushStateHistory(this.agency);
    this.tableRows = [];
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    await api
      .getStbUsers(this.agency)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        res.data.forEach((d: any) => {
          if (d[4] == 1) {
            this.tableRows.push({
              id: d[0],
              username: d[1],
              firstname: d[2],
              lastname: d[3],
              level: d[4],
              userflags: d[5] || 0,
              authTypeText: getAuthTypeText(d[5]),
            });
          }
        });
        this.filteredRows = this.tableRows;
        this.totalItems = this.filteredRows.length;
        // console.log('this.totalItems',this.totalItems)
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  getMandators() {
    if (!this.agency) {
      return;
    }
    api
      .getMandators(this.agency)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.mandators = res.data;
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  get clients() {
    return store.getters.clients;
  }

  get client() {
    return store.getters.selectedClient;
  }

  get agency() {
    return store.getters.selectedAgency;
  }

  preventDefault(e: any) {
    e.stopPropagation();
  }

  passwordGenerate() {
    this.user.password = "";
  }

  topBarSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.cachedAgencies = this.agencies.filter((itm: any) => {
        if (
          this.dropdownSearch == "" ||
          itm[0].toLowerCase().includes(this.dropdownSearch.toLowerCase()) ||
          itm[1].toLowerCase().includes(this.dropdownSearch.toLowerCase())
        ) {
          return itm;
        }
      });
    }, 300);
  }

  pushStateHistory(stb: any) {
    const selectedStb = this.agency != null ? this.agency : stb;
    let path =
      "/einstellungen/kanzlei/admins" + "/" + encodeURIComponent(selectedStb);
    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  getAgencieName(agencyId: string) {
    const agency = this.agencies.find((a) => a[0] === agencyId);
    return agency[1];
  }

  getAgencies() {
    api.getAllStbs().then((res) => {
      const data: [string, string][] = res.data;
      const sortedAgencies = data.sort((a, b) => a[0].localeCompare(b[0]));
      this.agencies = sortedAgencies;
      this.cachedAgencies = sortedAgencies;
      if (this.agency == null) {
        store.dispatch("updateSelectedAgency", sortedAgencies[0][0]);
      }
    });
  }

  @Watch("agency")
  onClientChange() {
    if (this.agency) {
      this.getEmployees();
    }
  }

  changefilter(val: any, key: string) {
    this.offsetPage = 0;
    this.getEmployees();
  }

  setAgency(agency: any) {
    localStorage.setItem("agency", JSON.stringify(agency));
    setAgencyStorage(agency);
    store.dispatch("updateSelectedAgency", agency);
  }

  clearOnEsc() {
    this.dropdownSearch = "";
    this.cachedAgencies = this.agencies;
    //A way to handle Jquery component inside Vuejs
    const _bodySelector = document.querySelector("body");
    if (_bodySelector) {
      _bodySelector.click();
    }
  }
  addModal() {
    this.user = {
      firstname: "",
      lastname: "",
      username: "",
      level: 1,
      password: "",
      passwordGenerated: false,
      userflags: 0,
    };
    this.userId = "";
    this.isErrorUsername = false;
    $("#add_modal").modal();
  }

  edit(event: any) {
    this.isErrorUsername = false;
    this.user = event;
    this.userId = event.id;
    $("#add_modal").modal();
  }

  changePermission(event: any) {
    this.user = event;
    this.userId = event.id;
    $("#permission_modal").modal();
  }

  onDeleteModal(event: any) {
    $("#delete_modal").modal();
    this.userToDelete = event.id;
  }

  deleteItem(event: any) {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    // api.deleteEmployee(this.agency, this.userToDelete).then(res => {
    api
      .deleteUser(this.userToDelete)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.getEmployees();
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  add() {
    this.isErrorUsername = false;
    this.isErrorEmail = false;

    // Escap HTML and Script to prevent XSS
    this.user = {
      ...this.user,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      username: this.user.username,
    };

    if (isEmailValid(this.user.username)) {
      this.isErrorEmail = true;
      return;
    }

    if (this.userId) {
      this.tableRows.forEach((row) => {
        if (row.id === this.userId) {
          row = { ...this.user, id: row.id };
        }
      });

      // waiting for update API
      api
        .updateUser(this.userId, { ...this.user, enabled: true })
        .then((res) => {
          if (this.user.passwordGenerated) {
            api.forgotPassword(this.user.username).then((res) => {});
          } else {
            store.dispatch("updateLoading", store.state.loading - 1);
          }
          this.getEmployees();
          $("#add_modal").modal("hide");
        })
        .catch((err) => {
          this.isErrorUsername = true;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .postAdmin(this.agency, this.user)
        .then((res) => {
          if (this.user.passwordGenerated) {
            api.setGeneratedPasswordUsers(res.data.id).then((res) => {
              store.dispatch("updateLoading", store.state.loading - 1);
            });
          } else {
            store.dispatch("updateLoading", store.state.loading - 1);
          }
          this.getEmployees();
          $("#add_modal").modal("hide");
        })
        .catch((err) => {
          this.isErrorUsername = true;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  search(event: any) {
    if (event) {
      this.filteredRows = this.tableRows.filter((row) => {
        return (
          row.firstname.toLowerCase().includes(event.toLowerCase()) ||
          row.lastname.toLowerCase().includes(event.toLowerCase()) ||
          row.id
            .toString()
            .toLowerCase()
            .includes(event.toString().toLowerCase()) ||
          row.level
            .toString()
            .toLowerCase()
            .includes(event.toString().toLowerCase()) ||
          row.username.toLowerCase().includes(event.toLowerCase())
        );
      });
    } else {
      this.filteredRows = this.tableRows;
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = "asc";
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }

  onAssignClients(event: any) {
    this.user = event;
    this.userId = event.id;
    $("#assign_clients_modal").modal();
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .getEmployeeMandators(this.agency, this.userId)
      .then((res) => {
        this.empMandators = res.data;
        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  linkClient(event: any, mandator: any) {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    if (event.target.checked) {
      api
        .linkEmployeeMandator(this.agency, mandator[2], this.userId)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else {
      api
        .unlinkEmployeeMandator(this.agency, mandator[2], this.userId)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }
}
